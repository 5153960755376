import { createMemoryHistory, createRouter, createWebHistory } from 'vue-router'
import {AreaType} from "@/service/model/product/AreaType";
import {LocationType} from "@/service/HeaderController";

export default function () {

  const history = import.meta.env.SSR ? createMemoryHistory() : createWebHistory()

  return createRouter({
    history: history,
    routes: [
        // 帮助页
      {
        path: '/help',
        component: () => import('../views/help/Help.vue'),
            children: [
              {
                path: '/HelpCenter/HelpAsk',
                name: 'HelpAsk',
                component: () => import('@/views/help/helpRight/helpAsk/HelpAsk.vue'),
                meta:{
                  headType: LocationType.OTHER
                }
              },
              {
                path: '/HelpCenter',
                name: 'Help Center',
                component: () => import('@/views/help/helpRight/helpList/HelpList.vue'),
                meta:{
                  headType: LocationType.OTHER
                }
              },
              {
                path: '/HelpCenter/HelpCategory',
                name: 'HelpCategory',
                component: () => import('@/views/help/helpRight/helpList/HelpList.vue'),
                meta:{
                  headType: LocationType.OTHER
                }
              },
              {
                path: '/HelpCenter/HelpDetail',
                name: 'HelpDetail',
                component: () => import('@/views/help/helpRight/helpDetail/HelpDetail.vue'),
                meta:{
                  headType: LocationType.OTHER
                }
              },
              {
                path: '/HelpCenter/HelpSearch',
                name: 'HelpSearch',
                component: () => import('@/views/help/helpRight/helpSearch/HelpSearch.vue'),
                meta:{
                  headType: LocationType.OTHER
                }
              }
            ]
      },
      {
        path: '/p-:productId(\\d+)-:pathMatch(.*).html',
        name: 'ProductDetail',
        component: () => import('@/views/productDetail/ProductDetail.vue'),
        meta:{
          headType: LocationType.OTHER
        }
      },
        // not found
      {
        path: '/PageNotFound',
        name: 'PageNotFound',
        component: () => import('@/views/NotFound.vue'),
        meta:{
          headType: LocationType.OTHER
        }
      },
      {
        path: '/Error',
        name: 'Error',
        component: () => import('@/views/Error.vue'),
        meta:{
          headType: LocationType.OTHER
        }
      },
      {
        path: '/',
        name: 'index',
        component: () => import('@/views/index/Index.vue'),
        meta:{
          headType: LocationType.OTHER
        }
      },
      {
        path:'/productImageSearch',
        name: 'SearchImg',
        component: () => import('@/views/product/searchImg/SearchImg.vue'),
        meta:{
          headType: LocationType.OTHER
        }
      },
      {
        path: '/top-100-sales/:page(\\d+).html',
        name: 'TopSales',
        meta:{
          disableGlobalContStyle: true,
          headType: LocationType.OTHER
        },
        component: () => import('@/views/product/weeklyHotList/WeeklyHotList.vue'),
      },
      {
        path: '/weekly-hot-listing/:page(\\d+).html',
        name: 'WeeklyHotList',
        meta:{
          disableGlobalContStyle: true,
          headType: LocationType.OTHER
        },
        component: () => import('@/views/product/weeklyHotList/WeeklyHotList.vue'),
      },
      {
        path: '/mix-beads-wholesale/:page(\\d+).html',
        name: 'MixProductsList',
        component: () => import('@/views/product/productList/Product.vue'),
        meta:{
          areaType: AreaType.MixProducts,
          headType: LocationType.OTHER
        }
      },
      {
        path: '/mix-beads-wholesale/:categoryName/:categoryId(\\d+)-:page(\\d+).html',
        name: 'MixProductsCategoryList',
        component: () => import('@/views/product/productList/Product.vue'),
        meta:{
          areaType: AreaType.MixProducts,
          headType: LocationType.OTHER
        }
      },
      {
        path: '/mix-beads-wholesale/:categoryName/:categoryId(\\d+)-:page(\\d+)-:propertyValue(.*).html',
        name: 'MixProductsCategoryPropertyList',
        component: () => import('@/views/product/productList/Product.vue'),
        meta:{
          areaType: AreaType.MixProducts,
          headType: LocationType.OTHER
        }
      },
      {
        path: '/mix-beads-wholesale/:page(\\d+)-:propertyValue(.*).html',
        name: 'MixProductsPropertyList',
        component: () => import('@/views/product/productList/Product.vue'),
        meta:{
          areaType: AreaType.MixProducts,
          headType: LocationType.OTHER
        }
      },
      {
        path: '/new-beads-wholesale/:page(\\d+).html',
        name: 'NewArrivalsList',
        component: () => import('@/views/product/productList/Product.vue'),
        meta:{
          areaType: AreaType.NewArrivals,
          headType: LocationType.OTHER
        }
      },
      {
        path: '/new-beads-wholesale/:categoryName/:categoryId(\\d+)-:page(\\d+).html',
        name: 'NewArrivalsCategoryList',
        component: () => import('@/views/product/productList/Product.vue'),
        meta:{
          areaType: AreaType.NewArrivals,
          headType: LocationType.OTHER
        }
      },
      {
        path: '/new-beads-wholesale/:categoryName/:categoryId(\\d+)-:page(\\d+)-:propertyValue(.*).html',
        name: 'NewArrivalsCategoryPropertyList',
        component: () => import('@/views/product/productList/Product.vue'),
        meta:{
          areaType: AreaType.NewArrivals,
          headType: LocationType.OTHER
        }
      },
      {
        path: '/new-beads-wholesale/:page(\\d+)-:propertyValue(.*).html',
        name: 'NewArrivalsPropertyList',
        component: () => import('@/views/product/productList/Product.vue'),
        meta:{
          areaType: AreaType.NewArrivals,
          headType: LocationType.OTHER
        }
      },
      {
        path: '/best-seller.html',
        name: 'BestSellersIndex',
        component: () => import('@/views/product/bestSeller/bestSeller.vue'),
        meta:{
          areaType: AreaType.BestSellers,
          headType: LocationType.OTHER
        }
      },
      {
        path: '/best-seller/:page(\\d+).html',
        name: 'BestSellersList',
        component: () => import('@/views/product/productList/Product.vue'),
        meta:{
          areaType: AreaType.BestSellers,
          headType: LocationType.OTHER
        }
      },
      {
        path: '/best-seller/:categoryName/:categoryId(\\d+)-:page(\\d+).html',
        name: 'BestSellersCategoryList',
        component: () => import('@/views/product/productList/Product.vue'),
        meta:{
          areaType: AreaType.BestSellers,
          headType: LocationType.OTHER
        }
      },
      {
        path: '/best-seller/:categoryName/:categoryId(\\d+)-:page(\\d+)-:propertyValue(.*).html',
        name: 'BestSellersCategoryPropertyList',
        component: () => import('@/views/product/productList/Product.vue'),
        meta:{
          areaType: AreaType.BestSellers,
          headType: LocationType.OTHER
        }
      },
      {
        path: '/best-seller/:page(\\d+)-:propertyValue(.*).html',
        name: 'BestSellersPropertyList',
        component: () => import('@/views/product/productList/Product.vue'),
        meta:{
          areaType: AreaType.BestSellers,
          headType: LocationType.OTHER
        }
      },
      {
        path: '/small-lot/:page(\\d+).html',
        name: 'SmallLotList',
        component: () => import('@/views/product/productList/Product.vue'),
        meta:{
          areaType: AreaType.Small_Lot,
          headType: LocationType.OTHER
        }
      },
      {
        path: '/small-lot/:categoryName/:categoryId(\\d+)-:page(\\d+).html',
        name: 'SmallLotCategoryList',
        component: () => import('@/views/product/productList/Product.vue'),
        meta:{
          areaType: AreaType.Small_Lot,
          headType: LocationType.OTHER
        }
      },
      {
        path: '/small-lot/:categoryName/:categoryId(\\d+)-:page(\\d+)-:propertyValue(.*).html',
        name: 'SmallLotCategoryPropertyList',
        component: () => import('@/views/product/productList/Product.vue'),
        meta:{
          areaType: AreaType.Small_Lot,
          headType: LocationType.OTHER
        }
      },
      {
        path: '/small-lot/:page(\\d+)-:propertyValue(.*).html',
        name: 'SmallLotPropertyList',
        component: () => import('@/views/product/productList/Product.vue'),
        meta:{
          areaType: AreaType.Small_Lot,
          headType: LocationType.OTHER
        }
      },
      {
        path: '/bontique/:page(\\d+).html',
        name: 'SelectionList',
        component: () => import('@/views/product/productList/Product.vue'),
        meta:{
          areaType: AreaType.Selection,
          headType: LocationType.OTHER
        }
      },
      {
        path: '/bontique/:categoryName/:categoryId(\\d+)-:page(\\d+).html',
        name: 'SelectionCategoryList',
        component: () => import('@/views/product/productList/Product.vue'),
        meta:{
          areaType: AreaType.Selection,
          headType: LocationType.OTHER
        }
      },
      {
        path: '/bontique/:categoryName/:categoryId(\\d+)-:page(\\d+)-:propertyValue(.*).html',
        name: 'SelectionCategoryPropertyList',
        component: () => import('@/views/product/productList/Product.vue'),
        meta:{
          areaType: AreaType.Selection,
          headType: LocationType.OTHER
        }
      },
      {
        path: '/bontique/:page(\\d+)-:propertyValue(.*).html',
        name: 'SelectionPropertyList',
        component: () => import('@/views/product/productList/Product.vue'),
        meta:{
          areaType: AreaType.Selection,
          headType: LocationType.OTHER
        }
      },
      {
        path: '/ProductSearch',
        name: 'ProductSearch',
        component: () => import('@/views/product/productList/search/Search.vue'),
        meta:{
          areaType: AreaType.SearchArea,
          headType: LocationType.OTHER
        }
      },
      {
        path: '/rs-wholesale-:keyword-:keyId(\\d+)-:page(\\d+).html',
        name: 'HotKeywordSearch',
        component: () => import('@/views/product/productList/search/Search.vue'),
        meta:{
          areaType: AreaType.SearchArea,
          headType: LocationType.OTHER
        }
      },
      {
        path: '/similar/:productId(\\d+)-:mode(\\d+)-:page(\\d+).html',
        name: 'SimilarItemList',
        component: () => import('@/views/product/similar/Similar.vue'),
        meta:{
          areaType: AreaType.SimilarItem,
          headType: LocationType.OTHER
        }
      },
      {
        path: '/:categoryName/:categoryId(\\d+)-:page(\\d+).html',
        name: 'NormalAreaProductList',
        component: () => import('@/views/product/productList/Product.vue'),
        meta:{
          areaType: AreaType.NormalArea,
          headType: LocationType.OTHER
        }
      },
      {
        path: '/:categoryName/:categoryId(\\d+)-:page(\\d+)-:propertyValue(.*).html',
        name: 'NormalAreaProductCategoryPropertyList',
        component: () => import('@/views/product/productList/Product.vue'),
        meta:{
          areaType: AreaType.NormalArea,
          headType: LocationType.OTHER
        }
      },
      {
        path: '/ShoppingCart-ShoppingCart-:page(\\d+)',
        name: 'ShoppingCart',
        component: () => import('@/views/cart/Cart.vue'),
        meta: {
          headType: LocationType.CART,
        }
      },
      {
        path: '/Order/CheckOut',
        name: 'CheckOut',
        component: () => import('@/views/checkout/Checkout.vue'),
        meta: {
          headType: LocationType.CHECKOUT,
        }
      },
      {
        path: '/PaypalError',
        name: 'PaypalError',
        component: () => import('@/views/cart/paypalError/PaypalError.vue'),
        meta: {
          title:'PayPal Error - cobeads'
        }
      },
        // 登录页
     /* {
        path: '/Customer/SignIn',
        name: 'Sign In / Register',
        component: () => import('@/views/login/LoginPage.vue')
      }, */
        // my account
      {
        path:'/myaccount',
        component: () => import('@/views/myaccount/Home.vue'),
        children: [
          {
            path: '/MyAccount',
            name: 'My Account',
            component: () => import('@/views/myaccount/home/Home.vue'),
            meta: {
              name: 'My Account',
              title:'My Account',
              headType: LocationType.OTHER
            }
          },
          {
            path: '/MyAccount/Newsletter',
            name: 'newsletter',
            component: () => import('@/views/myaccount/newsletter/Newsletter.vue'),
            meta: {
              name: 'Newsletter',
              title:'newsletter',
              headType: LocationType.OTHER
            }
          },
          {
            path: '/MyAccount/AccountSetting',
            name: 'accountSetting',
            component: () => import('@/views/myaccount/accountSetting/AccountSetting.vue'),
            meta: {
              name: 'Account Setting',
              title:'accountSetting',
              headType: LocationType.OTHER
            }
          },
          {
            path: '/TicketCenter/UnreadedTickets',
            name: 'Unread Tickets',
            component: () => import('@/views/myaccount/ticket/ticketList/TicketList.vue'),
            meta: {
              name: 'Unread Tickets',
              title:'Unread Tickets',
              headType: LocationType.OTHER
            }
          },
          {
            path: '/TicketCenter/AllTickets',
            name: 'allTickets',
            component: () => import('@/views/myaccount/ticket/ticketList/TicketList.vue'),
            meta: {
              name: 'All Tickets',
              title:'AllTickets',
              headType: LocationType.OTHER
            }
          },
          {
            path: '/MyAccount/myFootPrint',
            name: 'MyFootPrint',
            component: () => import('@/views/myaccount/personalization/myFootprint/MyFootprint.vue'),
            meta: {
              name: 'MyFootPrint',
              title:'MyFootPrint',
              headType: LocationType.OTHER
            }
          },
          {
            path: '/TicketCenter/TicketDetails',
            name: 'ticketDetails',
            component: () => import('@/views/myaccount/ticket/ticketDetail/TicketDetail.vue'),
            meta: {
              name: 'Ticket Details',
              title:'TicketDetails',
              headType: LocationType.OTHER
            }
          },
          {
            path: '/TicketCenter/Complaint',
            name: 'complaint',
            component: () => import('@/views/myaccount/ticket/ticketEdit/TicketEdit.vue'),
            meta: {
              name: 'Complaint',
              title:'Complaint',
              headType: LocationType.OTHER
            }
          },
          {
            path: '/TicketCenter/SubmitATicket',
            name: 'Submit a ticket',
            component: () => import('@/views/myaccount/ticket/ticketEdit/TicketEdit.vue'),
            meta: {
              name: 'Submit a ticket',
              title:'Submit a ticket'
            }
          },
          {
            path: '/Coupon/Coupon',
            name: 'coupon',
            component: () => import('@/views/myaccount/coupon/Coupon.vue'),
            meta: {
              name: 'Coupon',
              title:'coupon',
              headType: LocationType.OTHER
            }
          },
          {
            path: '/Points',
            name: 'points',
            component: () => import('@/views/myaccount/points/Points.vue'),
            meta: {
              name: 'Points',
              title:'points',
              headType: LocationType.OTHER
            }
          },
          {
            path: '/MyAccount/MyReviews',
            name: 'MyReviews',
            component: () => import('@/views/myaccount/personalization/myReviews/MyReview.vue'),
            meta: {
              name: 'MyReviews',
              title:'MyReviews',
              headType: LocationType.OTHER
            }
          },
          {
            path: '/MyAccount/AddressBook',
            name: 'Address Book',
            component: () => import('@/views/myaccount/personalization/addressBook/addressBookList/AddressBook.vue'),
            meta: {
              name: 'Address Book',
              title:'Address Book',
              headType: LocationType.OTHER
            }
          },
          {
            path: '/MyAccount/AddressBookDetails',
            name: 'AddressBookDetails',
            component: () => import('@/views/myaccount/personalization/addressBook/addressBookDetail/AddressBookDetail.vue'),
            meta: {
              name: 'Address Book Details',
              title:'Address Book Detail',
              headType: LocationType.OTHER
            }
          },
          {
            path: '/WishList/MyWishList',
            name: 'MyWishList',
            component: () => import('@/views/myaccount/personalization/myWishList/myWishList/MyWishList.vue'),
            meta: {
              name: 'MyWishList',
              title:'MyWishList',
              headType: LocationType.OTHER
            }
          },
          {
            path: '/WishList/:id.html',
            name: 'MyWishListDetail',
            component: () => import('@/views/myaccount/personalization/myWishList/myWishListDetail/MyWishListDetail.vue'),
            meta: {
              name: 'MyWishList',
              title:'MyWishListDetail',
              headType: LocationType.OTHER
            }
          },
          {
            path: '/MyAccount/PaymentHistory',
            name: 'paymentHistory',
            component: () => import('@/views/myaccount/paymentHistory/PaymentHistory.vue'),
            meta: {
              name: 'Payment History',
              title:'payment History',
              headType: LocationType.OTHER
            }
          },
          {
            path: '/Special-Bargains-for-You',
            name: 'Special-Bargains-for-You',
            component: () => import('@/views/myaccount/personalization/specialBargainsForYou/SpecialBargainsForYou.vue'),
            meta: {
              name: 'Special Bargains for You',
              title:'Special-Bargains-for-You',
              headType: LocationType.OTHER
            }
          },
          {
            path: '/MyAccount/OnlyForYouProducts',
            name: 'Only For You',
            component: () => import('@/views/myaccount/personalization/onlyForYou/OnlyForYou.vue'),
            meta: {
              name: 'Only For You',
              title:'Only For You',
              headType: LocationType.OTHER
            }
          },
          {
            path: '/MyAccount/CanceledProducts',
            name: 'Canceled Products',
            component: () => import('@/views/myaccount/purchaseHistory/cancelledItems/CancelledItems.vue'),
            meta: {
              name: 'Canceled Products',
              title:'Canceled Products',
              headType: LocationType.OTHER
            }
          },
          {
            path: '/MyAccount/PurchaseHistory',
            name: 'PurchaseHistory',
            component: () => import('@/views/myaccount/purchaseHistory/purchaseHistory/PurchaseHistory.vue'),
            meta: {
              name: 'Purchase History',
              title:'Purchase History',
              headType: LocationType.OTHER
            }
          },
          {
            path: '/Cash/CashAccount',
            name: 'Cash Account',
            component: () => import('@/views/myaccount/cashAccount/CashAccount.vue'),
            meta: {
              name: 'Cash Account',
              title:'Cash Account',
              headType: LocationType.OTHER
            }
          },
          {
            path: '/Order/MyOrders',
            name: 'My Orders',
            component: () => import('@/views/myaccount/order/orderList/OrderList.vue'),
            meta: {
              name: 'My Orders',
              title:'My Orders',
              headType: LocationType.OTHER
            }
          },
          {
            path: '/Order/OrderDetails',
            name: 'Order Details',
            component: () => import('@/views/myaccount/order/orderDetail/OrderDetail.vue'),
            meta: {
              name: 'Order Details',
              title:'Order Details',
              headType: LocationType.OTHER
            }
          },
          {
            path: '/Order/ReviewOrder/:id.html',
            name: 'Order Review',
            component: () => import('@/views/myaccount/order/orderDetail/orderReview/OrderReview.vue'),
            meta: {
              name: 'Order Review',
              title:'Order Details',
              headType: LocationType.OTHER
            }
          },
        ]
      },
      {
        path: '/Order/OrderItemDetails',
        name: 'Order OrderItemDetails',
        component: () => import('@/views/myaccount/order/orderItemDetail/OrderItemDetail.vue'),
        meta: {
          name: 'Order OrderItemDetails',
          title:'Order OrderItemDetails',
          headType: LocationType.OTHER
        }
      },
      {
        path: "/Order/PrintOrderInvoice",
        name: "PrintInvoice",
        component: () => import('@/views/myaccount/order/orderDetail/printOrderInvoice/PrintOrderInvoice.vue'),
        props: (route) => ({
          orderId: route.query.orderId,
          isCombine: route.query.isCombine
        }),
        meta: {
          isPrint: true
        }
      },
      {
        path: "/Replacement/SimilarItems",
        name: "SimilarList",
        component: () => import('@/views/replacement/list/similarList/SimilarList.vue'),
        meta:{
          areaType: AreaType.Remplacement_SimilarItem,
          isReplacementPage: true
        }
      },
      {
        path: "/Replacement/ShowStockProduct",
        name: "ShowStockProduct",
        component: () => import('@/views/replacement/list/stockProductList/StockProductList.vue'),
        meta:{
          areaType: AreaType.Remplacement,
          isReplacementPage: true
        }
      },
      {
        path: "/Replacement/showShoppingCartStockProduct",
        name: "ShowShoppingCartStockProduct",
        component: () => import('@/views/replacement/list/replacementListFromShopping/ReplacementListFromShopping.vue'),
        meta:{
          areaType: AreaType.Remplacement_In_ShoppingCart,
          isReplacementPage: true
        }
      },
      {
        path: "/Replacement/showWishListStockProduct",
        name: "ShowWishListStockProduct",
        component: () => import('@/views/replacement/list/replacementListFromWishList/ReplacementListFromWishList.vue'),
        meta:{
          areaType: AreaType.Remplacement_In_ShoppingCart,
          isReplacementPage: true
        }
      },
      {
        path: "/Replacement/ShowReplacement",
        name: "ShowReplacement",
        component: () => import('@/views/replacement/replacementDetails/ReplacementDetails.vue'),
        meta:{
          isReplacementPage: true
        }

      },
      {
        path: "/customer/showRefuseReplacement",
        name: "ShowRefuseReplacement",
        component: () => import('@/views/replacement/refuseDetails/RefuseDetails.vue'),
        meta:{
          isReplacementPage: true
        }
      },
      {
        path: "/customer/showPartOrder",
        name: "ShowPartOrder",
        component: () => import('@/views/replacement/partOrderDetails/PartOrderDetails.vue'),
        meta:{
          isReplacementPage: true
        }
      },
      {
        path: '/.+',
        name: 'PageNotFound',
        component: () => import('@/views/NotFound.vue')
      },
    ],
  })
}